<template>
  <el-dialog
    width="600px"
    :title="$t('practice.form.createNewPracticeFromCopy')"
    :visible.sync="visibleCopyDialog"
    :before-close="
      () => {
        $emit('toggleVisibleCopyDialog', false);
      }
    "
    center
  >
    <div style="text-align: center">
      <el-checkbox v-model="copyAndShareToOriginalStudent">
        {{ $t("practice.form.copyAndShareToOriginalStudent") }}
      </el-checkbox>
      <el-checkbox v-model="copyAndShareToOriginalEditor">
        {{ $t("practice.form.copyAndShareToOriginalEditor") }}
      </el-checkbox>
    </div>
    <hr class="separate-line" />
    <div style="text-align: right">
      <el-button
        @click="
          () => {
            $emit('toggleVisibleCopyDialog', false);
          }
        "
      >
        {{ $t("button.cancel") }}
      </el-button>
      <el-button @click="copyAndCreateAPractice" type="primary">
        {{ $t("practice.form.createNewPracticeFromCopy") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
import practiceApi from "@/apis/practices";
import Practice from "@/views/practices/Practice";

export default {
  props: {
    practiceId: {
      type: Number,
      default: null
    },
    visibleCopyDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      copyAndShareToOriginalStudent: false,
      copyAndShareToOriginalEditor: false
    };
  },
  methods: {
    async copyAndCreateAPractice() {
      try {
        const {
          practice: { id }
        } = await practiceApi.copyPractice(this.practiceId, {
          has_copy_editor: this.copyAndShareToOriginalEditor,
          has_copy_taker: this.copyAndShareToOriginalStudent
        });
        this.$router.push({
          name: "EditPractices",
          params: {
            practiceId: id
          }
        });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
